html, body {
  height: 100%;
  margin: 0;
  font-family: proxima_nova_regular, Helvetica, Arial, sans-serif;
  font-size: .9em;
}

.map {
  width: 100%;
  height: 100%;
}

#controls-left {
  background-color: #fff;
  min-width: 30em;
  max-width: 30em;
  height: 100%;
  margin-bottom: 100px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.directional-control {
  background-color: #0000;
  border-top: 1px solid gray;
  border-radius: 0;
  height: 170px;
  padding: 5px;
  position: static;
}

.directional-button {
  position: absolute;
}

.wkt-input {
  border-top: 1px solid gray;
  width: 90%;
  padding: 5px;
  position: static;
}

.wkt-input textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: monospace;
  font-size: 1.1em;
}

.wkt-input textarea.error {
  background-color: #f0acac;
}

.lat-long-input {
  z-index: 10;
  background-color: #0000;
  padding-left: 5px;
  position: static;
}

.date-input {
  z-index: 10;
  padding: 0;
  position: static;
}

.date-input li {
  padding: 5px 10px;
  display: block;
}

.sdo-input {
  z-index: 10;
  background-color: #0000;
  border-bottom: 1px solid gray;
  padding: 5px;
  position: static;
}

.sdo-text {
  margin-left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.sdo-area {
  margin-top: 2px;
  margin-bottom: 0;
}

.sdo-sign-span-div {
  color: #fff;
  white-space: nowrap;
  background-color: #718cae;
  border: 1px solid #718cae;
  border-radius: 5px;
  width: min-content;
  margin: 1px;
  padding: 4px 4px 4px 5px;
}

.sdo-sign-button {
  cursor: pointer;
  color: #fff;
  text-align: center;
  background-color: #f55a67;
  border-radius: 50%;
  padding: 4px 6px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

.sdo-sign-button:hover {
  background-color: #bd2a53;
}

.login {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
}

.login-btn {
  color: #fff;
  text-align: center;
  background-color: #6495ed;
  border: none;
  border-radius: 10px;
  margin: 10px auto 0;
  padding: 10px;
  font-size: 16px;
  display: inline-block;
}

.login-btn:hover {
  cursor: pointer;
  background-color: #4f83e4;
}

.data-source {
  position: block;
  z-index: 10;
  background-color: #0000;
  border-bottom: 1px solid gray;
  padding: 5px;
}

.data-source-label {
  margin: 0;
  padding: 0;
}

.vector-feature {
  position: block;
  z-index: 10;
  background-color: #0000;
  padding: 5px;
}

.vector-feature-text {
  margin-left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.layer-active {
  color: #000;
}

.layer-inactive {
  color: gray;
  max-width: 40em;
}

table.vector-feature-attributes-list {
  width: 90%;
}

table.vector-feature-attributes-list, table.feature-info-list {
  table-layout: fixed;
  max-width: 90%;
}

.vector-feature-attributes-list, .feature-info-list {
  padding-top: .3em;
  padding-bottom: .3em;
  padding-left: .3em;
  font-size: 1em;
}

.vector-feature-subattributes-list {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 1em;
}

.vector-feature-attributes-list col.label, .vector-feature-attributes-list td.label {
  width: 30%;
}

.vector-feature-attributes-list col.value, .vector-feature-attributes-list td.value {
  width: 70%;
}

.vector-feature-attributes-list td, .vector-feature-subattributes-list td {
  vertical-align: top;
  overflow-wrap: break-word;
}

#gcd-container {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 30.5em;
}

.gcd-gl-control {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1.7em;
  height: 2em;
  display: flex;
  overflow: hidden;
}

.gcd-gl-control button {
  margin-left: 3px;
}

.gcd-gl-control button:before {
  content: "";
  box-sizing: border-box;
  background-color: #0000;
  border: .12em solid;
  border-radius: 100%;
  width: .7em;
  height: .7em;
  position: absolute;
  top: .35em;
  left: .35em;
}

.gcd-gl-control button:after {
  content: "";
  background-color: currentColor;
  border-radius: .05em;
  width: .45em;
  height: .15em;
  position: absolute;
  top: 1.1em;
  left: .95em;
  transform: rotate(45deg);
  box-shadow: -.18em 0 0 -.03em;
}

.gcd-gl-control input {
  display: block;
}

.gcd-gl-expanded {
  width: 14em;
}

.gcd-gl-expanded input {
  width: 11em;
  height: 1.2em;
  display: inline;
}

.ol-geocoder ul.gcd-gl-result {
  white-space: normal;
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 4px 4px;
  width: 16.25em;
  max-height: 18.75em;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: max-height .3s ease-in;
  position: absolute;
  top: 2.1875em;
  left: 2em;
  overflow: hidden auto;
  box-shadow: 0 1px 7px #000c;
}

ul.gcd-gl-result > li:nth-child(odd) {
  background-color: #e0ffe0;
}

ul.gcd-gl-result > li {
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 0;
  line-height: .875rem;
  overflow: hidden;
}

ul.gcd-gl-result > li > a {
  padding: 3px 5px;
  text-decoration: none;
  display: block;
}

.gcd-road {
  color: #333;
  font-size: .875em;
  font-weight: 500;
}

.layer-switcher {
  text-align: left;
  position: absolute;
  top: .5em;
  right: .5em;
}

.layer-switcher .panel {
  max-height: inherit;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #eee;
  border-radius: 4px;
  height: 100%;
  margin: 0;
  display: none;
  overflow-y: auto;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  color: #000;
  background-color: #0000;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: 2px;
  background-repeat: no-repeat;
  border: none;
  width: 36px;
  height: 36px;
  margin: -2px;
}

.layer-switcher button:focus, .layer-switcher button:hover {
  background-color: #fff;
}

.layer-switcher.shown {
  flex-direction: column;
  max-height: calc(100% - 5.5em);
  display: flex;
  overflow-y: hidden;
}

.layer-switcher.shown.ol-control, .layer-switcher.shown.ol-control:hover {
  background-color: #0000;
}

.layer-switcher.shown .panel {
  display: block;
}

.layer-switcher.shown button {
  display: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  background-image: unset;
  background-color: #eee;
  margin: 1px;
  display: block;
  position: absolute;
  right: 2px;
}

.layer-switcher.shown button:focus, .layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  margin: 1.6em .4em;
  padding-left: 0;
  list-style: none;
}

.layer-switcher ul ul {
  margin: .1em 0 0;
  padding-left: 1.2em;
}

.layer-switcher li.group + li.group {
  margin-top: .4em;
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  margin-top: .3em;
  position: relative;
}

.layer-switcher li input {
  width: 1em;
  height: 1em;
  font-size: 1em;
  position: absolute;
  left: 1.2em;
}

.layer-switcher li label {
  margin-top: 1px;
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
}

.layer-switcher label.disabled {
  opacity: .4;
}

.layer-switcher input {
  margin: 0;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  vertical-align: top;
  float: none;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  -ms-transition: -ms-transform .2s ease-in-out;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==");
  background-position: 50% 2px;
  width: 1em;
  height: 1em;
  margin: 0;
  font-size: 1em;
  transition: transform .2s ease-in-out;
  display: inline-block;
  position: absolute;
  left: 0;
}

.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  height: 0;
  overflow: hidden;
}

.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  border-right: 0;
  left: 0;
}

#feature-info {
  z-index: 100;
  color: #fff;
  text-align: center;
  visibility: visible;
  pointer-events: none;
  background-color: #333;
  border-radius: 4px;
  width: auto;
  height: auto;
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 30.5em;
  transform: translateX(3%);
}

details {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
  transition: background-color .3s;
}

summary {
  cursor: pointer;
  outline: none;
  font-weight: bold;
}
/*# sourceMappingURL=open-maps-restriction-wkt-viewer.9ea14beb.css.map */
